import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ContentChildren,
  Output,
  Input,
  EventEmitter,
} from '@angular/core'
import {auditTime, fromEvent, Observable, Subject} from 'rxjs'
import {SliderItemDirective} from './slider-item.directive'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'cft-list-slider',
  templateUrl: './slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply flex items-start gap-xs;
        @apply -mr-2 pr-4;
        @apply overflow-x-auto;
        @apply snap-x snap-mandatory;
      }
      :host(::-webkit-scrollbar) {
        display: none;
      }
    `,
  ],
})
export class SliderComponent implements AfterViewInit, OnDestroy {
  @Input() activeItemIndexAt$?: Observable<number>
  @Output() activated = new EventEmitter<number>()

  private currentIndex = 0

  private readonly destroy$ = new Subject<true>()
  @ContentChildren(SliderItemDirective) items!: SliderItemDirective[]
  constructor(private ref: ElementRef) {}

  ngAfterViewInit() {
    if (this.activeItemIndexAt$) {
      this.activeItemIndexAt$.pipe(takeUntil(this.destroy$)).subscribe(index => {
        if (index !== this.currentIndex) {
          const sliderWidth = this.ref.nativeElement.scrollWidth / this.items.length
          this.ref.nativeElement.scrollTo({left: sliderWidth * index, behavior: 'smooth'})
        }
      })
    }

    fromEvent(this.ref.nativeElement, 'scroll')
      .pipe(auditTime(100))
      .subscribe(() => {
        const sliderWidth = this.ref.nativeElement.scrollWidth / this.items.length
        const newIndex = Math.round((this.ref.nativeElement.scrollLeft + sliderWidth) / sliderWidth) - 1
        if (this.currentIndex !== newIndex) {
          this.currentIndex = newIndex
          this.activated.emit(this.currentIndex)
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
