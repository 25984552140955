<div *cftI18n="let t">
  @if (showSelectAll) {
    <cft-list-item [size]="'md'" [selected]="isAllSelected()" (selectedChange)="toggleSelectAll()" class="mb-2xs">
      {{ t('chipsSelectAll') }}
    </cft-list-item>
  }
  @for (item of items; track trackByFn($index, item)) {
    <cft-list-item
      [icon]="item.icon"
      [size]="'md'"
      [selected]="isSelected(item) && !isAllSelected()"
      (selectedChange)="toggleSelection(item)"
      class="mb-2xs"
    >
      {{ item.label }}
    </cft-list-item>
  }
</div>
