<div
  *cftI18n="let t"
  class="flex content-start overflow-x-auto pt-px"
  [class.flex-wrap]="wrap"
  [class.chip-nowrap]="!wrap"
  [class.default]="size === 'default'"
  [class.sm]="size === 'sm' || size === 'md'"
  [class.xs]="size === 'xs'"
  (scroll)="scroll($event)"
>
  @if (showSelectAll) {
    <cft-list-item
      class="min-w-10 text-center"
      [class.whitespace-nowrap]="!wrap"
      [size]="size"
      [selected]="isAllSelected()"
      (selectedChange)="toggleSelectAll()"
    >
      {{ t('chipsSelectAll') }}
    </cft-list-item>
  }
  @for (item of items; track trackByFn($index, item)) {
    <cft-list-item
      [class.whitespace-nowrap]="!wrap"
      [icon]="item.icon"
      [size]="size"
      [selected]="isSelected(item) || isAllSelected()"
      (selectedChange)="toggleSelection(item)"
    >
      {{ item.label }}
    </cft-list-item>
  }
</div>
