import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core'
import {NG_VALUE_ACCESSOR} from '@angular/forms'
import {ListComponent} from '../list.component'

@Component({
  selector: 'cft-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChipsComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsComponent<T> extends ListComponent<T> {
  @Input() size: 'sm' | 'md' | 'default' | 'xs' = 'default'
  @Input() wrap = true
  @Output() scrolling = new EventEmitter<{start: boolean; end: boolean; left: boolean; right: boolean}>()

  private scrollLeft = 0

  constructor(injector: Injector, cdr: ChangeDetectorRef) {
    super(injector, cdr)
  }

  scroll(event: Event): void {
    const div = event.target! as HTMLDivElement
    this.scrolling.emit({
      start: div.scrollLeft === 0,
      end: div.scrollLeft + div.offsetWidth === div.scrollWidth,
      left: div.scrollLeft < this.scrollLeft,
      right: div.scrollLeft > this.scrollLeft,
    })
    this.scrollLeft = div.scrollLeft
  }
}
