<ul class="flex gap-1">
  @for (_ of items(itemsCount).fill(1); track _; let i = $index) {
    <li class="flex h-md w-md items-center justify-center">
      <button
        [ngClass]="{
          'h-[0.5rem] w-[0.5rem] bg-grey-300': i !== activeIndex,
          'h-[0.75rem] w-[0.75rem] bg-president-500': i === activeIndex
        }"
        class="rounded-full"
        type="button"
        (click)="setActive(i)"
      ></button>
    </li>
  }
</ul>
