import {
  ChangeDetectionStrategy,
  Component,
  Output,
  Input,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import {Observable, Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'cft-list-bullet-indicator',
  templateUrl: './bullet-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host {@apply block;}'],
})
export class BulletIndicatorComponent implements AfterViewInit, OnDestroy {
  protected readonly items = Array

  protected activeIndex = 0

  @Input()
  itemsCount!: number

  @Input()
  activeIndexAt$?: Observable<number>

  @Output()
  readonly activated = new EventEmitter<number>()

  private readonly destroy$ = new Subject<true>()

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.activeIndexAt$?.pipe(takeUntil(this.destroy$)).subscribe(index => {
      if (index === undefined && index > 0 && index < this.itemsCount) {
        throw new RangeError(`Index (${index}) is not in the valid range of the items count (0-${this.itemsCount})`)
      }
      if (index !== this.activeIndex) {
        this.activeIndex = index
        this.cdr.detectChanges()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  setActive(i) {
    this.activeIndex = i
    this.activated.emit(this.activeIndex)
  }
}
