import {Directive, EventEmitter, HostListener, Output, Input, HostBinding} from '@angular/core'

@Directive({
  selector: '[cftListGridItem]',
})
export class GridItemDirective {
  @HostBinding('class.list-grid__item--active')
  isActive = false

  @Input()
  set activate(isActive: boolean) {
    this.isActive = isActive
  }

  @Output()
  activated: EventEmitter<true> = new EventEmitter<true>()

  @HostListener('click')
  private click() {
    this.isActive = true
    this.activated.emit(true)
  }
}
