@if (navigationType !== 'none') {
  <ng-container *transloco="let t">
    <cft-icon-button
      [iconColor]="navigationColor"
      [icon]="previousIcon"
      [bgIntensity]="navigationType === 'ghost' ? 'ghost' : '10'"
      class="arrows"
      [ngClass]="{
        'stick-middle-left': navigationType === 'floating',
        'ghost-left override-ghost-padding': navigationType === 'ghost'
      }"
      [hasShadow]="hasShadow"
      (click)="prev()"
      [ariaLabel]="t('shared.previous')"
    ></cft-icon-button>
    <cft-icon-button
      [iconColor]="navigationColor"
      [icon]="nextIcon"
      [bgIntensity]="navigationType === 'ghost' ? 'ghost' : undefined"
      class="arrows"
      [ngClass]="{
        'stick-middle-right': navigationType === 'floating',
        'ghost-right override-ghost-padding': navigationType === 'ghost'
      }"
      [hasShadow]="hasShadow"
      (click)="next()"
      [ariaLabel]="t('shared.next')"
    ></cft-icon-button>
  </ng-container>
}

<div #carouselContainer class="carousel-container">
  <ng-content></ng-content>
</div>

<div class="pagination-container">
  <ng-content select="pagination"></ng-content>
</div>
