import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'cft-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() size: 'xs' | 'sm' | 'md' | 'default' = 'default'
  @Input() icon?: {name: string; collection: string}
  @Input() selected = false
  @Output() selectedChange = new EventEmitter<boolean>()

  toggleSelection(): void {
    this.selectedChange.emit(!this.selected)
  }
}
