<div
  class="cursor-pointer rounded-2 border-2 font-normal sm:hover:bg-president-50"
  [ngClass]="{
    'px-xl py-sm sm:px-4 sm:text-lg': size === 'default',
    'border-transparent bg-president-50 text-president-400': !selected
  }"
  [class.selected]="selected"
  [class.py-xs]="size === 'md'"
  [class.py-px]="size === 'sm' || size === 'xs'"
  [class.px-xs]="size === 'sm' || size === 'xs'"
  [class.lg:px-lg]="size === 'md'"
  [class.px-sm]="size === 'md'"
  [class.text-sm]="size === 'sm'"
  [class.text-xs]="size === 'xs'"
  [class.text-base]="size === 'md'"
  (click)="toggleSelection()"
>
  @if (icon) {
    <cft-dynamic-icon [icon]="icon" class="pr-xs"></cft-dynamic-icon>
  }
  <ng-content></ng-content>
</div>
