import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit} from '@angular/core'
import {ControlValueAccessorConnectorDirective} from '../forms/control-value-accessor-connector.directive'
import {ListItem} from './list-item.model'
import {NG_VALUE_ACCESSOR} from '@angular/forms'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'cft-list',
  templateUrl: './list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ListComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent<T> extends ControlValueAccessorConnectorDirective implements OnInit, OnDestroy {
  @Input() items: ListItem<T>[] = []
  @Input() showSelectAll = false
  @Input() onlyOneSelectable = false
  private unsubscribe$ = new Subject<void>()

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(injector)
  }

  get selectedItems(): T[] | undefined {
    return this.control?.value
  }

  ngOnInit(): void {
    this.control?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cdr.markForCheck())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  trackByFn(index: number, item: ListItem<T>): keyof T {
    return item.equalityKey
  }

  toggleSelection(chip: ListItem<T>): void {
    let selectedItems

    if (this.onlyOneSelectable) {
      selectedItems = [chip.item]
    } else {
      selectedItems = this.selectedItems ?? []
      const index = selectedItems.findIndex(i => chip.equals(i))
      if (index > -1) {
        selectedItems.splice(index, 1)
      } else {
        selectedItems.push(chip.item)
      }
    }

    this.control?.setValue(selectedItems)
  }

  isSelected(chip: ListItem<T>): boolean {
    return this.selectedItems?.some(i => chip.equals(i)) ?? false
  }

  isAllSelected(): boolean {
    return this.items?.every(c => this.isSelected(c))
  }

  toggleSelectAll(): void {
    if (this.isAllSelected() && !this.onlyOneSelectable) {
      this.control?.setValue([])
    } else {
      this.control?.setValue(this.items.map(c => c.item))
    }
  }
}
