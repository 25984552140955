import {Directive, ElementRef, HostBinding} from '@angular/core'

@Directive({
  selector: '[cftListCarouselSlide]',
})
export class CarouselSlideDirective {
  constructor(public ref: ElementRef<HTMLElement>) {}

  @HostBinding('class.min-w-full')
  minWidth = true

  @HostBinding('class.snap-start')
  snapCenter = true

  @HostBinding('class.block')
  flex = true

  stopVideo() {
    const ytIframeWindow = this.ref.nativeElement?.querySelector('iframe')?.contentWindow
    //We could also just pause the video, but here it is stopped
    ytIframeWindow?.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
  }
}
