import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {isPlatformBrowser} from '@angular/common'

@Injectable({providedIn: 'root'})
export class IntersectionObserverService {
  constructor(@Inject(PLATFORM_ID) readonly platformId: Record<string, unknown>) {}

  createIntersectionObserver(
    cb: IntersectionObserverCallback,
    init: IntersectionObserverInit,
  ): IntersectionObserverInterface {
    return isPlatformBrowser(this.platformId)
      ? new IntersectionObserver(cb, init)
      : new ServerInteractionObserver(cb, init)
  }
}

// on the server, IntersectionObserver is not implemented, provide an empty implementation
class ServerInteractionObserver implements IntersectionObserver {
  readonly root: Element | Document | null = null
  readonly rootMargin: string = ''
  readonly thresholds: readonly number[] = []

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(cb: IntersectionObserverCallback, init: IntersectionObserverInit) {}

  takeRecords(): IntersectionObserverEntry[] {
    return []
  }
  observe() {
    return null
  }
  unobserve() {
    return null
  }
  disconnect() {
    return null
  }
}

export interface IntersectionObserverInterface {
  observe(e: unknown): void
  unobserve(e: unknown): void
}
