import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  ViewChild,
} from '@angular/core'
import {CarouselSlideDirective} from './carousel-slide.directive'
import {Observable, Subject} from 'rxjs'
import {IntersectionObserverInterface, IntersectionObserverService} from '../../utils/intersection-observer.service'
import {takeUntil} from 'rxjs/operators'
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {isPlatformBrowser} from '@angular/common'
import {TextColour} from '../../utils/colour.types'

type NavigationType = 'none' | 'floating' | 'ghost'

@Component({
  selector: 'cft-list-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('carouselContainer') carouselRef!: ElementRef<HTMLElement>
  @ContentChildren(CarouselSlideDirective) slides!: QueryList<CarouselSlideDirective>

  @Output() activated = new EventEmitter<number>()

  @Input() activeItemIndexAt$?: Observable<number>

  @Input() hasShadow = true

  @Input() navigationType: NavigationType = 'floating'

  @Input() navigationColor?: TextColour

  private observer!: IntersectionObserverInterface
  private activeIndex = 0

  protected nextIcon = faChevronRight
  protected previousIcon = faChevronLeft

  private readonly destroy$ = new Subject<true>()

  get nextSlideIndex(): number {
    return (this.activeIndex + 1) % this.slides.length
  }
  get previousSlideIndex(): number {
    return (this.activeIndex - 1 + this.slides.length) % this.slides.length
  }

  constructor(
    private el: ElementRef,
    private readonly intersectionObserverService: IntersectionObserverService,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  // Lifecycle
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.activeItemIndexAt$?.pipe(takeUntil(this.destroy$)).subscribe(index => {
        this.setActiveSlide(index)
      })
      this.observeSlides()
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.slides.forEach(slide => {
        this.observer.unobserve(slide.ref.nativeElement)
      })
    }
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  //Interactions
  next() {
    this.setActiveSlide(this.nextSlideIndex)
  }

  prev() {
    this.setActiveSlide(this.previousSlideIndex)
  }

  setActiveSlide(index: number) {
    if (index === this.activeIndex) return
    this.activeIndex = index
    this.scrollToItem(index)
  }

  private scrollToItem(index: number) {
    this.slides.get(index)?.ref.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  //Observing slides
  private observeSlides() {
    const options = {
      root: this.carouselRef.nativeElement,
      threshold: 0.5, // at least 50% of the slide is visible
    }
    this.observer = this.intersectionObserverService.createIntersectionObserver(this.updateActiveIndexOnScroll, options)

    this.slides.forEach((slide, i) => {
      slide.ref.nativeElement.dataset.index = `${i}`
      this.observer.observe(slide.ref.nativeElement)
    })
  }

  private updateActiveIndexOnScroll = (entries: IntersectionObserverEntry[]) => {
    entries
      .filter(({isIntersecting}) => isIntersecting)
      .forEach(entry => {
        this.slides.forEach((slide, i) => {
          if (slide.ref.nativeElement === entry.target) {
            this.activeIndex = i
            this.activated.emit(this.activeIndex)
          } else {
            slide.stopVideo()
          }
        })
        this.activated.emit(this.activeIndex)
      })
  }
}
