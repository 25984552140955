import {Directive, HostBinding} from '@angular/core'
import {GridItemDirective} from '../grid/grid-item.directive'

@Directive({
  selector: '[cftListSliderItem]',
})
export class SliderItemDirective extends GridItemDirective {
  @HostBinding('class.min-w-full')
  minWidth = true

  @HostBinding('class.snap-start')
  snapCenter = true
}
